@import "compass/reset";
@import "config";
@import "common";
@import "compass/css3";
@import "compass/utilities";
@import "responsive";
html {
  min-height: 100%;
}
header.cabecera{
  position: relative;
  height: 425px;
}
.mobile-only{
  display: none;
}
@include responsive('max-midphablet') {
  .desktop-only{
    display: none;
  }
  .mobile-only{
    display: block;
  }
}
body {

  background: $blanco;
  height: 100%;

  .lo-main {
    min-height: $lo-min-height;
  }

  div#contentPjax {
    /*padding-bottom: 78px;*/
  }

  #header {


    min-height: 120px;

    .header-top {
      height: $header-top-height;
      background: $azul-acs-claro;
      position: relative;

      /* widget de idiomas */
      ul.list-lang {

        font-size: 12px;
        padding-top: 4px;
        position: relative;

        li {
          display: inline-block;
          &:not(:last-child):after{
            content: "|";
            display: inline-block;
            color: $azul-acs-oscuro;
            margin: 0 5px;
          }
          a{
            color: $azul-acs-medio;
          }
          &.actual a{
            color: #fff
          }

        }

        // fin li

      }

      /* FIN widget de idiomas */

    }

    // FIN HEADER-TOP

    .header-main {
      height: 105px;
      background: $azul-acs-medio;

      .wrapper-navbar {
        padding-top: 25px;

        #menu-nav {
          position: relative;

          li.nav-item {
            margin: 0;
            position: relative;
            text-transform: uppercase;
            a {
              text-decoration: none;
              color: #fff;
              &.active{
                font-weight: 700;
              }
            }
          }
          .dropdown{
            .dropdown-menu {
              a.dropdown-item {
                text-transform: none;
                color: $azul-acs-claro;

                &:hover {
                  color: #fff;
                }
              }
            }
            &:hover{
              .dropdown-menu{
                display: block;
                margin-top: 0; /* remove the gap so it doesn't close */
              }
            }
          }

        }
      }
    }

    .lo-logo {
      position: absolute;
      top: ($header-top-height*-1);
      left: 75px;
      z-index: 102;
      @include responsive('max-midphablet') {
        width: 50%;
      }
    }


    .menu-programas-wrap {

      overflow: hidden;
      width: 315px;
      height: 425px;
      position: relative;
      /*
      background: $azul-acs-oscuro;
      background: rgba($azul-acs-oscuro, 0.4);
      */
      background: url($lo-image-path + 'pixelmn.png') repeat;
      z-index: 101;
      left: 0;
      top: 17px;

      ul#menu-programas {

        width: 100%;
        padding: 10% 5%;
        ul.submenu{
          padding-left: 15px;
          margin-bottom: 15px;
          li{
            a{
              padding: 4px 0 4px 20px;
            }
          }
        }
        ul.submenu.hidden{
          display: none;
        }
        .dropdown-toggle.active ~ .dropdown-menu{
          display: block;
        }
        li.nav-item {
          display: block;
          width: 100%;
          a{
            color: #fff;
            white-space: pre-wrap;
            &.active{
              font-weight: 700;
            }
            &:hover{
              text-decoration: underline;
            }
          }
        }
        .dropdown-toggle{
          //padding-bottom: 0;

        }
        .dropdown-menu{
          position: relative;
          background: transparent;
          border: none;
          padding: 0 0 15px 0;
          .dropdown-item{
            font-size: 14px;
            padding-left: 30px;
            &:hover{
              background: transparent;
            }
            &.active{
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  #menu-programas-mobile{
    padding-top: 0;

    .nav-item{
      display: block;
      width: 100%;
      border-bottom: 1px solid #d2d2d2;
      position: relative;

      .nav-link{
        color: #000;
        &:after{
          position: absolute;
          right: 5px;
          content: ">";
          color: #000;
          font-size: 16px;
          margin: auto;
          top: 6px;

        }
        &.active{
          font-weight: 700;
        }

      }
    }
    ul.submenu{
      padding-left: 15px;
      margin-bottom: 15px;
      margin-top: 5px;
      li{
        display: block;
        width: 100%;
        border-bottom: none;
        a{
          padding: 4px 0 4px 20px;
          &:after{
            top: 4px !important;

          }
          &.active{
            font-weight: 700;
          }
        }
      }
    }
    ul.submenu.hidden{
      display: none;
    }
  }
  div#footer {
    width: 100%;
    background: $azul-acs-claro;
    color: $blanco;
    border-bottom: solid 5px $azul-acs-claro;
    //margin-top:2em;
    // position: absolute;
    position: relative;
    bottom: 0;
    padding-top: 2em;
    padding-bottom: 0em;
    z-index: 999;
    overflow: hidden;
    margin-top: 10px;
    .copy {

    }

    ul#footer-menu {
      // line-height: 58px;
      li {
        display: inline-block;

        a, span {
          font-size: .9em;
          color: #fff;
          padding-right: .5em;
          padding-left: .5em;
          border-left: 1px solid $azul-acs-oscuro;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        span {
          &:hover {
            text-decoration: none;
          }
        }

        &:first-child {
          a {
            border-left: none;
          }
        }
      }
    }

    #logo-confianza {
      display: inline-block;
      width: 60px;

      img {
        width: 100%;
      }
    }

    #logo-footer {
      display: inline-block;
      width: 125px;
      padding: 16px 0 0;

      img {
        width: 100%;
      }
    }

    .container {
      float: none;
      height: 45px;
    }

  }


  /* slick-carrusel */
  .slick-wrap{
    z-index: 0;
    height: 425px;
  }
  .slick-wrap, .detalle-noticias-img {
    overflow: hidden;
  }

  .a-slide {
    height: 100%;
    width: 100%;
    background-size: cover !important;
    background-position: center right;
  }

  .slick-slider {
    height: 100%;
  }

  .slick-list,
  .slick-track {
    height: 100%;
  }

  .slick-prev, .slick-next {
    position: absolute;
    display: block;
    height: 39px;
    width: 19px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    margin-top: -10px;
    padding: 0;
    border: none;
    outline: none;
    z-index: 9999;
  }

  .slick-prev:hover, .slick-prev:focus {
    background: url($image-path + 'common/default-images/flecha_izq.png') no-repeat;
  }

  .slick-next:hover, .slick-next:focus {
    background: url($image-path + 'common/default-images/flecha_dcha.png') no-repeat;
  }

  .slick-prev {
    left: 0;
    background: url($image-path + 'common/default-images/flecha_izq.png') no-repeat;

    &:before {
      content: '';
    }
  }

  .slick-next {
    right: 0;
    background: url($image-path + 'common/default-images/flecha_dcha.png') no-repeat;

    &:before {
      content: '';
    }
  }

  /* listview noticias */

  .noticias-main-titulo {
    text-align: center;
    color: $azul-acs-oscuro;
    text-transform: uppercase;
    font-weight: bolder;
    margin: 1em auto;
    font-size: x-large;

  }

  ul#list-wrapper.lv-noticias-item {

    position: relative;
    overflow: hidden;

    .noticias-sep {

      height: 206px;
      // width: 1px;
      position: absolute;
      bottom: 38px;
      background: $azul-acs-claro;
      left: 0px;
      border-right: 1px solid #89a9c4;

    }

    li {

      text-align: center;
      height: auto;
      min-height: 350px;

      font-size: 12px;

      div {

        margin: 10px auto;
        margin-bottom: 1.4em;


        &.noticias-img {
          max-width: 270px;
          max-height: 270px;
          min-height: 204px;
        }

        &.noticias-lv-wrap {

          padding: 10%;
          min-height: 439px;

          .noticias-titulo {

            height: 100px;

          }

          /*
          &:nth-child(n+1) .noticias-wrap{

            border-left:  1px solid $azul-acs-claro;
            border-right: 1px solid $azul-acs-claro;

          }
          */


        }

        .noticias-titulo {
          line-height: 18px;
        }

        .detalle-noticias-img {

          img {
            margin: 0 auto;
          }


        }

        .detalle-noticias-descripcion {

          line-height: 22px;

        }

      }

    }

    /*
    .pagination{

      ul.pagination{
        width: 50%;
        margin: 0 auto;
      }

    }
    */


  }

  // list view detalle
  div.detalle-noticias-lv-wrap {

    width: 80%;
    max-width: 800px;
    margin: 0 auto;

    .detalle-noticias-img {
      max-width: 100%;
    }
  }

}

// widget noticias detalle // paginacion noticia detalle
div.noticias-pager {

  &.detalle {

    ul.pagination {
      overflow: hidden;

      li {

        &.prev {
          left: 0;
          position: absolute;
          top: 175px;
          z-index: 998;
        }

        &.next {
          right: 0;
          position: absolute;
          top: 175px;
          z-index: 998;
        }

        span, a {
          border: none;
          color: $azul-acs-claro;
          background-color: transparent;
          text-transform: capitalize;

          .lt, .gt {
            color: $azul-acs-medio !important;
            font-size: 20px;
          }

          &:hover, &:active, &:visited, &:focus {
            background: none !important;
            color: $azul-acs-oscuro !important;
            font-weight: 700;
          }
        }

      }
    }
  }

}

// fin noticias-pager

@media (max-width: 1200px) {

  // ocultar el separador de las noticias
  .noticias-sep {
    display: none;
  }

}

@media (max-width: 990px) {

  #menu-nav {

    position: relative;
    margin: 0 auto;
    position: relative;
    //top:70px !important;
    display: table;
    vertical-align: middle;
    max-width: 700px !important;
    font-size: 7px;
    text-align: right;
    padding: 0 !important;

    li {

      display: inline-block;
      margin: 0;
      position: relative;

      &.active {
        a {
          font-weight: bolder;
          /*
                    &:before{
                      content: "";
                      position: absolute;
                      width: 20%;
                      height: 2px;
                      bottom: 10px;
                      left: 40%;
                      background-color: $blanco;
                    }
          */
        }

      }

      a {
        text-decoration: none;
        padding: 10px 12px;
        //color: $blanco;
      }

    }

  }


}

@media (max-width: 1199px) {
  body {

    div#footer {
      ul#footer-menu {
        line-height: 1em;

        li {
          display: block;

          a, span {
            border-left: none;
            padding-left: 0;
          }
        }
      }
    }
  }
}

.dropdown-menu {
  --bs-dropdown-bg: #{$azul-acs-medio};
  --bs-dropdown-link-hover-bg: #{$azul-acs-medio};
  --bs-dropdown-link-color: #{$azul-acs-claro};
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-link-active-bg: $azul-acs-medio
}
.site-error{
  margin-top: 90px;
  h3{
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 10px;
  }
}
@import "actividades";
@import "publicaciones";
@import "destacados";