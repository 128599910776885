@import "compass/utilities/sprites";

body {
  font-size:14px;
  font-family: 'Raleway', "Verdana", "arial";
}

b, strong{
  font-weight: 700;
}

.overflow-hidden{
  overflow: hidden;
}

.hidden-separator{
  &:before{
    content: " ";
    margin: 0 1em;
  }
}

.raleway-light{
  font-family: Raleway;
  font-weight: 100 !important;
}
.raleway-medium{
  font-family: Raleway;
  font-weight: 500 !important;
}
.raleway-bold{
  font-family: Raleway;
  font-weight: 700 !important;
}

.text-blanco{
  color: #ffffff !important;
}

.relative{
  position: relative;
}

.center {
  margin: 0 auto;
  display: block;
}

div, img{
  outline: none;
}

.text-right{
  text-align: right;
}
.text-center{
  text-align: center;
}
.text-left{
  text-align: left;
}
.text-justify{
  text-align: justify;
}

.social-icon {
  display: inline-block;
  width: 37px;
  height: 37px;
  margin:0 1px;

  img {
    max-width: 100%;
  }
}

a, a:hover, a:active {
  color: #fff;
}

a.rojo, a.rojo:hover {
  color: $rojo;
}

a.aspa {
  width:50px;
  display: block;
  img {
    width:100%;
  }
}

hr.undescore-sep{
  width: 25px;
  text-align: center;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 0;
  border-top: 6px solid $blanco;
  &.azul-oscuro{
    border-top: 6px solid $azul-acs-oscuro;
  }
  &.azul-medio{
    border-top: 6px solid $azul-acs-medio;
  }
  &.azul-claro{
    border-top: 6px solid $azul-acs-claro;
  }
}

.under-separator{
  &:after{
    content: "-";
    color:  $blanco;
    position: absolute;
    font-size: 60px;
    bottom: 15px;
    width: 100%;
    text-align: center;
  }
}
.entradilla{

}

@media (max-width: 767px) {
  .social-icon {
    width: 26px;
    height: 26px;
  }

  a.aspa {
    width:32px;
    display: block;
    img {
      width:100%;
    }
  }
}

.txt-right {
  text-align: right;
}

.rojo {
  color: $rojo;
}

.rojo-claro {
  color: $rojo-claro;
}

.rojo-oscuro {
  color: $rojo-oscuro;
}

.azul-claro{
  color: $azul-acs-claro;
}
.azul-oscuro{
  color: $azul-acs-oscuro;
}
.azul-medio{
  color: $azul-acs-medio;
}

.blanco {
  color: #fff;
}

.amarillo {
  color: $amarillo;
}

.din-bold {
  font-family:'DIN Next W01 Bold',Arial, Helvetica, Sans-Serif;
}

.neue-roman {
  font-family:'HelveticaNeueW01-55Roma',Arial, Helvetica, Sans-Serif;
}

.neue-light {
  font-family:'HelveticaNeueW01-45Ligh',Arial, Helvetica, Sans-Serif;
}

.neue-italic {
  font-family:'HelveticaNeueW01-56It',Arial, Helvetica, Sans-Serif;
}

.neue-bold {
  font-family:'HelveticaNeueW01-75Bold',Arial, Helvetica, Sans-Serif;
}

.neue-bold-condensed {
  font-family:'HelveticaNeueW01-77BdCn_692722',Arial, Helvetica, Sans-Serif;
}

.neue-heavy {
  font-family:'HelveticaNeueW01-85Heav',Arial, Helvetica, Sans-Serif;
}

.neue-heavy-italic {
  font-family:'HelveticaNeueW01-86Heav',Arial, Helvetica, Sans-Serif;
}

.btn {
  background: $azul-acs-claro;
  color: #fff;
  padding: 10px 25px;
  border-width: 0;
  transition: background-color 0.5s ease;
  line-height: 1em;

    font-weight: bolder;
    font-size: 12px;

  &:hover, &:focus, &:active {
    color: #fff;
    text-decoration: none;
    background: $azul-acs-oscuro;
  }

  &:visited{
    background: $azul-acs-claro;
    &:hover, &:focus, &:active {
      background: $azul-acs-oscuro;
    }
  }

  &.btn-dark {
    background: black;
    &:hover {
      background:  $azul-acs-oscuro;
    }
  }

  &.btn-amarillo {
    background: $amarillo;
    &:hover {
      background: $amarillo-claro;
    }
  }

  &.btn-volver{
    color: $azul-acs-claro !important;
    background: $blanco;
    border:1px solid $azul-acs-claro
  }

}

.loader {
  display: none;
  height: 1px;
  width: 1px;
  font-size: 10px;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: fixed;
  left: 50%;
  top: 50%;
  animation: load3 1.4s infinite linear;
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: $rojo;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    transform: translate(-50%,-50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%,-50%) rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    transform: translate(-50%,-50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%,-50%) rotate(360deg);
  }
}
