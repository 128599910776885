@import "config";
@import "common";
.actividades-titulo{
  color: $azul-acs-claro;
  font-size: 35px;
  line-height: 16px;
  font-weight: 900;
  margin: 0 0 25px 0;
  text-transform: uppercase;
}
.actividades-wrapper{
  padding-top: 40px;
  padding-bottom: 40px;
  box-sizing: border-box;
  &:before{
    content:'';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: solid 20px $blanco;
    border-left: solid 20px transparent;
    border-right: solid 20px transparent;
  }

}
.actividades-content{
  background: $azul-acs-claro;

  color: #fff;
  position: relative;
  h3{
    font-size: 26px;
    font-weight: 900;
    color: #fff;
    text-transform: uppercase;
    line-height: 22px;
    margin-bottom: 32px;
  }
  .actividad{
    margin: 8px 0;
  }
}
.interior-contenido{

  .actividades-descripcion{
    width: 100%;
    background: $blanco;
    color: $azul-acs-oscuro;
    padding: 2em;
    line-height: 18px;
    text-align: center;
    position:relative;
    font-size: x-large;
    font-weight: bolder;



  }

  .actividades-content{

    background: $azul-acs-claro;
    color: $blanco;
    padding: 4em 0;
    margin-bottom: 3em;
    h3{
      font-size: 28px;
      margin: 0 0 15px 0;
      font-weight: 700;
    }
    .static{
      background: $azul-acs-claro !important;
      color: #fff !important;
    }
    .actividad{
      margin: 5px 0;
    }
    .interior-static{
      color: #fff !important;

    }

  }

  ul#list-wrapper-actividades{

    line-height: 20px;
    font-size: 12px !important;

  }


}

